// agentConfig.js
const agentConfig = {
    116: {
        accountId: 'N00000062988',
        agentNumber: '8000@sryhq',
        password: 'Bh8D6zrL4z8000'
    },
    102: {
        accountId: 'N00000062988',
        agentNumber: '8001@sryhq',
        password: 'MCz4kYdku8001'
    },
    115: {
        accountId: 'N00000062988',
        agentNumber: '8002@sryhq',
        password: 'MCz4kYdku8002'
    },
    104: {
        accountId: 'N00000062988',
        agentNumber: '8003@sryhq',
        password: 'MCz4kYdku8003'
    },
    105: {
        accountId: 'N00000062988',
        agentNumber: '8004@sryhq',
        password: 'MCz4kYdku8004'
    },
    101: {
        accountId: 'N00000062988',
        agentNumber: '8005@sryhq',
        password: 'MCz4kYdku8005'
    },
    107: {
        accountId: 'N00000062988',
        agentNumber: '8006@sryhq',
        password: 'MCz4kYdku8006'
    },
    113: {
        accountId: 'N00000062988',
        agentNumber: '8007@sryhq',
        password: 'MCz4kYdku8007'
    },
    114: {
        accountId: 'N00000062988',
        agentNumber: '8008@sryhq',
        password: 'MCz4kYdku8008'
    },
    110: {
        accountId: 'N00000062988',
        agentNumber: '8009@sryhq',
        password: 'MCz4kYdku8009'
    },
    111: {
        accountId: 'N00000062988',
        agentNumber: '8010@sryhq',
        password: 'MCz4kYdku8010'
    },
    112: {
        accountId: 'N00000062988',
        agentNumber: '8011@sryhq',
        password: 'MCz4kYdku8011'
    },
    // 添加更多的座席配置
};

module.exports = agentConfig;